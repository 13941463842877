"use strict";

var topNav = document.getElementById("top-nav");
var linkToCurrentPage = topNav.firstElementChild;
[...topNav.children].forEach((el) => {
  if (window.location.href.includes(el.dataset.path)) {
    linkToCurrentPage = el;
  }
});

// if (
//   ["squarespace", "wix", "wordpress", "custom-integration"].some(function (
//     path
//   ) {
//     return window.location.href.indexOf(path) >= 0;
//   })
// ) {
//   linkToCurrentPage = document.getElementById("integrations-nav");
// }
linkToCurrentPage.classList.add("underline", "underline-offset-8");

const navSlide = () => {
  const burger = document.querySelector(".burger");
  const nav = document.querySelector(".nav-links");
  // const navLinks = document.querySelectorAll(".nav-links a");

  burger.addEventListener("click", () => {
    nav.classList.toggle("nav-active");
    burger.classList.toggle("toggle");
  });
};

navSlide();

// document.addEventListener("DOMContentLoaded", () => {
//   const dropdownToggles = document.querySelectorAll(".dropdown-toggle");

//   dropdownToggles.forEach((toggle) => {
//     toggle.addEventListener("click", () => {
//       const dropdownMenu = toggle.nextElementSibling;

//       if (dropdownMenu.classList.contains("hidden")) {
//         document.querySelectorAll(".dropdown-menu").forEach((menu) => {
//           menu.classList.add("hidden");
//         });

//         dropdownMenu.classList.remove("hidden");
//       } else {
//         dropdownMenu.classList.add("hidden");
//       }
//     });
//   });

//   window.addEventListener("click", (event) => {
//     if (!event.target.matches(".dropdown-toggle")) {
//       document.querySelectorAll(".dropdown-menu").forEach((menu) => {
//         if (!menu.contains(event.target)) {
//           menu.classList.add("hidden");
//         }
//       });
//     }
//   });
// });
